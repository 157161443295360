import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SubpageHero from "../components/subPageHero"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SubpageWrapper from "../components/subpageWrapper"

const AboutPage = () => (
  <Layout>
    <Seo title="About Priority One" />
    <SubpageHero h1="About" />
    <SubpageWrapper></SubpageWrapper>
  </Layout>
)

export default AboutPage